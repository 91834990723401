<template>
	<v-select :items="items" v-bind="{value, label, filled, disabled, clearable, hideDetails, errorMessages, multiple}" :loading="loading" item-value="id" item-text="name" @input="input" @click:clear="clickClear" no-filter></v-select>
</template>
<script>

import API from '@/api/depots'

export default {
	data () {
		return {
			items: [],
			loading: false
		}
	},
	props: {
		value: null,
		label: String,
		multiple: Boolean,
		filled: Boolean,
		hideDetails: Boolean,
		disabled: Boolean,
		clearable: Boolean,
		errorMessages: null
	},
	computed: {
		currentSelectedRow () {
			if (this.value === null) return {}
			return this.items.reduce((a, v) => v.id === this.value ? v : a, {})
		}
	},
	methods: {
		input (v) {
			this.$emit('input', v)
		},
		clickClear () {
			this.$emit('click:clear')
		}
	},
	watch: {
		value () {
			this.$emit('data', this.currentSelectedRow)
		}
	},
	created () {
		this.loading = true
		API.loadList().then(({ data }) => {
			this.items = data.data
		}).catch(({ response: { data } }) => {
			this.$store.commit('core/snackbar/show', { text: data.message }, { root: true })
		}).finally(() => {
			this.loading = false
		})
	}
}
</script>
