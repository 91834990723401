import axios from 'axios'

export default {
	loadList (params = {}) {
		return axios.get(`/api/depots`, { params })
	},
	loadItem (id, opt = {}) {
		return axios.get(`/api/depots/${id}`, opt)
	},
	saveItem (data) {
		return axios.post('/api/depots', data)
	},
	deleteItem (data) {
		return axios.delete(`/api/depots/${data.id}`)
	}
}
